define("ember-paper/components/paper-menu", ["exports", "ember-paper/templates/components/paper-menu", "ember-basic-dropdown/components/basic-dropdown"], function (_exports, _paperMenu, _basicDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MENU_EDGE_MARGIN = 8;

  function clamp(pos, bounds, containerNode) {
    pos.top = Math.max(Math.min(pos.top, bounds.bottom - containerNode.offsetHeight), bounds.top);
    pos.left = Math.max(Math.min(pos.left, bounds.right - containerNode.offsetWidth), bounds.left);
  }

  function firstVisibleChild(node) {
    for (var i = 0; i < node.children.length; ++i) {
      if (window.getComputedStyle(node.children[i]).display !== 'none') {
        return node.children[i];
      }
    }
  }
  /**
   * @class PaperMenu
   * @extends BasicDropdownComponent
   */


  var _default = _basicDropdown.default.extend({
    layout: _paperMenu.default,
    close: function close() {
      this._super.apply(this, arguments);

      this.didAnimateScale = false;
    },
    position: 'target',
    // If attachment is a single item, duplicate it for our second value.
    // ie. 'target' -> 'target target'
    positionMode: Ember.computed('position', function () {
      var position = this.get('position') || 'target';

      var _position$split$map = position.split(' ').map(function (s) {
        return s.trim();
      }),
          _position$split$map2 = _slicedToArray(_position$split$map, 2),
          left = _position$split$map2[0],
          top = _position$split$map2[1];

      top = top || left;
      return {
        left: left,
        top: top
      };
    }),
    offset: '0 0',
    offsets: Ember.computed('offset', function () {
      var offset = this.get('offset') || '0 0';

      var _offset$split$map$map = offset.split(' ').map(function (s) {
        return s.trim();
      }).map(parseFloat),
          _offset$split$map$map2 = _slicedToArray(_offset$split$map$map, 2),
          left = _offset$split$map$map2[0],
          top = _offset$split$map$map2[1];

      top = top || left;
      return {
        left: left,
        top: top
      };
    }),
    calculatePosition: function calculatePosition(trigger, content, destination, _ref) {
      var dropdown = _ref.dropdown;
      var containerNode = content;
      var openMenuNode = content.firstElementChild;
      var openMenuNodeRect = openMenuNode.getBoundingClientRect();
      var boundryNode = document.body;
      var boundryNodeRect = boundryNode.getBoundingClientRect();
      var menuStyle = window.getComputedStyle(openMenuNode);
      var originNode = trigger.querySelector('.md-menu-origin') || trigger.querySelector('md-icon') || trigger;
      var originNodeRect = originNode.getBoundingClientRect();
      var bounds = {
        left: boundryNodeRect.left + MENU_EDGE_MARGIN,
        top: Math.max(boundryNodeRect.top, 0) + MENU_EDGE_MARGIN,
        bottom: Math.max(boundryNodeRect.bottom, Math.max(boundryNodeRect.top, 0) + boundryNodeRect.height) - MENU_EDGE_MARGIN,
        right: boundryNodeRect.right - MENU_EDGE_MARGIN
      };
      var alignTarget;
      var alignTargetRect = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
      var existingOffsets = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      };
      var positionMode = dropdown.get('positionMode');

      if (positionMode.top === 'target' || positionMode.left === 'target' || positionMode.left === 'target-right') {
        alignTarget = firstVisibleChild(openMenuNode);

        if (alignTarget) {
          // TODO: Allow centering on an arbitrary node, for now center on first menu-item's child
          alignTarget = alignTarget.firstElementChild || alignTarget;
          alignTarget = alignTarget.querySelector('md-icon') || alignTarget.querySelector('.md-menu-align-target') || alignTarget;
          alignTargetRect = alignTarget.getBoundingClientRect();
          existingOffsets = {
            top: parseFloat(containerNode.style.top || 0),
            left: parseFloat(containerNode.style.left || 0)
          };
        }
      }

      var position = {};
      var transformOrigin = 'top ';

      switch (positionMode.top) {
        case 'target':
          position.top = existingOffsets.top + originNodeRect.top - (alignTargetRect.top - openMenuNodeRect.top);
          break;

        case 'cascade':
          position.top = originNodeRect.top - parseFloat(menuStyle.paddingTop) - originNode.style.top;
          break;

        case 'bottom':
          position.top = originNodeRect.top + originNodeRect.height;
          break;

        default:
          (false && !(false) && Ember.assert("Invalid target mode '".concat(positionMode.top, "' specified for paper-menu on Y axis.")));
      }

      switch (positionMode.left) {
        case 'target':
          {
            position.left = existingOffsets.left + originNodeRect.left - (alignTargetRect.left - openMenuNodeRect.left);
            transformOrigin += 'left';
            break;
          }

        case 'target-left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        case 'target-right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width + (openMenuNodeRect.right - alignTargetRect.right);
            transformOrigin += 'right';
            break;
          }

        case 'cascade':
          {
            var willFitRight = originNodeRect.right + openMenuNodeRect.width < bounds.right;
            position.left = willFitRight ? originNodeRect.right - originNode.style.left : originNodeRect.left - originNode.style.left - openMenuNodeRect.width;
            transformOrigin += willFitRight ? 'left' : 'right';
            break;
          }

        case 'right':
          {
            position.left = originNodeRect.right - openMenuNodeRect.width;
            transformOrigin += 'right';
            break;
          }

        case 'left':
          {
            position.left = originNodeRect.left;
            transformOrigin += 'left';
            break;
          }

        default:
          {
            (false && !(false) && Ember.assert("Invalid target mode '".concat(positionMode.left, "' specified for paper-menu on X axis.")));
          }
      } // sum offsets


      var offsets = dropdown.get('offsets');
      position.top += offsets.top;
      position.left += offsets.left;
      clamp(position, bounds, containerNode);
      var dropdownTop = Math.round(position.top);
      var dropdownLeft = Math.round(position.left);
      var scaleX = Math.round(100 * Math.min(originNodeRect.width / containerNode.offsetWidth, 1.0)) / 100;
      var scaleY = Math.round(100 * Math.min(originNodeRect.height / containerNode.offsetHeight, 1.0)) / 100;
      var style = {
        top: dropdownTop,
        left: dropdownLeft,
        // Animate a scale out if we aren't just repositioning
        transform: !dropdown.didAnimateScale ? "scale(".concat(scaleX, ", ").concat(scaleY, ")") : undefined,
        transformOrigin: transformOrigin
      };
      dropdown.setProperties({
        transform: !dropdown.didAnimateScale ? "scale(".concat(scaleX, ", ").concat(scaleY, ")") : undefined,
        transformOrigin: transformOrigin
      });
      dropdown.didAnimateScale = true;
      return {
        style: style,
        horizontalPosition: '',
        verticalPosition: ''
      };
    }
  });

  _exports.default = _default;
});