define("ember-paper/components/paper-select-options", ["exports", "ember-power-select/components/power-select/options", "ember-paper/templates/components/paper-select-options"], function (_exports, _options, _paperSelectOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _options.default.extend({
    layout: _paperSelectOptions.default,
    tagName: 'md-content',
    init: function init() {
      if (this.get('role') === 'group') {
        this.set('tagName', '');
        this.set('attributeBindings', undefined);
      }

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('role') === 'group') {
        return;
      }

      var findOptionAndPerform = function findOptionAndPerform(action, e) {
        var optionItem = e.target.closest('[data-option-index]');

        if (!optionItem) {
          return;
        }

        if (optionItem.closest('[aria-disabled=true]')) {
          return;
        } // Abort if the item or an ancestor is disabled


        var optionIndex = optionItem.getAttribute('data-option-index');
        action(_this._optionFromIndex(optionIndex), e);
      };

      this.element.addEventListener('mouseup', function (e) {
        return findOptionAndPerform(_this.get('select.actions.choose'), e);
      });
      this.element.addEventListener('mouseover', function (e) {
        return findOptionAndPerform(_this.get('select.actions.highlight'), e);
      });

      if (this.get('isTouchDevice')) {
        this._addTouchEvents();
      }

      if (this.get('role') !== 'group') {
        var select = this.get('select');
        select.actions.scrollTo(select.highlighted);
      }
    }
  });

  _exports.default = _default;
});