define("ember-paper/components/paper-virtual-repeat-scroller", ["exports", "ember-paper/templates/components/paper-virtual-repeat-scroller"], function (_exports, _paperVirtualRepeatScroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _paperVirtualRepeatScroller.default,
    classNames: ['md-virtual-repeat-scroller'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._onScroll = function (e) {
        _this.get('onScroll')(e);
      };

      this.element.addEventListener('scroll', this._onScroll);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('scroll', this._onScroll);
      this._onScroll = null;
    }
  });

  _exports.default = _default;
});