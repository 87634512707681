define('ember-intl-tel-input/components/intl-tel-input', ['exports', 'ember-intl-tel-input/templates/components/intl-tel-input'], function (exports, _intlTelInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    layout: _intlTelInput.default,
    tagName: 'input',
    attributeBindings: ['type'],
    type: 'tel',

    /**
     * Whether or not to allow the dropdown. If disabled, there is no dropdown arrow,
     * and the selected flag is not clickable. Also we display the selected flag on
     * the right instead because it is just a marker of state.
     *
     * @property allowDropdown
     * @type Boolean
     * @default true
     */
    allowDropdown: true,

    /**
     * If there is just a dial code in the input: remove it on blur, and re-add
     * it on focus. This is to prevent just a dial code getting submitted with
     * the form. Requires `nationalMode` to be set to `false`.
     *
     * @property autoHideDialCode
     * @type Boolean
     * @default true
     */
    autoHideDialCode: true,

    /**
     * Set the input's placeholder to an example number for the selected country,
     * and update it if the country changes. You can specify the number type using
     * the `numberType` option. By default it is set to `"polite"`, which means
     * it will only set the placeholder if the input doesn't already have one.
     * You can also set it to `"aggressive"`, which will replace any existing
     * placeholder, or `"off"`. Requires the utilities script.
     *
     * @property autoPlaceholder
     * @type String
     * @default "polite"
     */
    autoPlaceholder: 'polite',

    /**
     * Change the placeholder generated by `autoPlaceholder`. Must return a string.
     *
     * @property customPlaceholder
     * @type Function
     * @default null
     */
    customPlaceholder: null,

    /**
     * Expects a jQuery selector to specify an element to append dropdown instad of
     * append next to element. Check [intl-tel-input options](https://github.com/jackocnr/intl-tel-input#options).
     *
     * @property dropdownContainer
     * @type String
     * @default ""
     */
    dropdownContainer: '',

    /**
     * Don't display the countries you specify.
     *
     * @property excludeCountries
     * @type Array
     * @default undefined
     */
    excludeCountries: undefined,

    /**
     * Format the input value (according to the `nationalMode` option) during
     * initialisation, and on `setNumber`. Requires the utilities script
     *
     * @property formatOnDisplay
     * @type Boolean
     * @default true
     */
    formatOnDisplay: true,

    /**
     * When setting `initialCountry` to `"auto"`, we need to use a special
     * service to lookup the location data for the user. Write a custom method to
     * get the country code.
     *
     * @property geoIpLookup
     * @type Function
     * @default null
     */
    geoIpLookup: null,

    /**
    * Set the default country by it's country code. You can also set it to
    * `"auto"`, which will lookup the user's country based on their
    * IP address - requires the `geoIpLookup` option - [see example](http://jackocnr.com/lib/intl-tel-input/examples/gen/default-country-ip.html).
    * Otherwise it will just be the first country in the list. **Note that if
    * you choose to do the auto lookup, and you also happen to use the
    * [jquery-cookie](https://github.com/carhartl/jquery-cookie) plugin, it
    * will store the loaded country code in a cookie for future use.**
    *
    * @property initialCountry
    * @type String
    * @default ""
    */
    initialCountry: '',

    /**
     * Allow users to enter national numbers (and not have to think about
     * international dial codes). Formatting, validation and placeholders still
     * work. Then you can use `getNumber` to extract a full international
     * number - [see example](http://jackocnr.com/lib/intl-tel-input/examples/gen/national-mode.html).
     * This option now defaults to `true`, and it is recommended that you leave it
     * that way as it provides a better experience for the user.
     *
     * @property nationalMode
     * @type Boolean
     * @default true
     */
    nationalMode: true,

    /**
     * Gets the type of the current `number`. Setting `numberType` when `value`
     * is empty and no custom placeholder is set will affect the format of the
     * auto placeholder. Requires the utilities script.
     *
     * Supported values:
     * - "FIXED_LINE"
     * - "MOBILE"
     * - "FIXED_LINE_OR_MOBILE"
     * - "TOLL_FREE"
     * - "PREMIUM_RATE"
     * - "SHARED_COST"
     * - "VOIP"
     * - "PERSONAL_NUMBER"
     * - "PAGER"
     * - "UAN"
     * - "VOICEMAIL"
     * - "UNKNOWN"
     *
     * @property numberType
     * @type String
     * @default "MOBILE"
     */
    numberType: Ember.computed('number', {
      get: function get() {
        if (this.hasUtilsScript()) {

          var typeNumber = this.$().intlTelInput('getNumberType');
          for (var key in intlTelInputUtils.numberType) {
            if (intlTelInputUtils.numberType[key] === typeNumber) {
              return key;
            }
          }
        }

        return 'MOBILE';
      },
      set: function set(key, newValue) {
        if (this.hasUtilsScript() && newValue in intlTelInputUtils.numberType) {
          return newValue;
        }

        return 'MOBILE';
      }
    }),

    /**
     * Display only the countries you specify - [see example](http://jackocnr.com/lib/intl-tel-input/examples/gen/only-countries-europe.html).
     *
     * @property onlyCountries
     * @type Array
     * @default "MOBILE"
     */
    onlyCountries: undefined,

    /**
     * Specify the countries to appear at the top of the list.
     *
     * @property preferredCountries
     * @type Array
     * @default ["us", "gb"]
     */
    preferredCountries: ['us', 'gb'],

    /**
     * Display the country dial code next to the selected flag so it's not part of
     * the typed number. Note that this will disable `nationalMode` because technically
     * we are dealing with international numbers, but with the dial code separated.
     *
     * @property separateDialCode
     * @type Boolean
     * @default false
     */
    separateDialCode: false,

    /**
     * Specify the format of the `number` property. Requires the utilities
     * script.
     *
     * Supported values:
     * - "E164"          e.g. "+41446681800"
     * - "INTERNATIONAL" e.g. "+41 44 668 1800"
     * - "NATIONAL"      e.g. "044 668 1800"
     * - "RFC3966"       e.g. "tel:+41-44-668-1800"
     *
     * @property numberFormat
     * @type String
     * @default 'E164'
     */
    _numberFormat: 'E164',
    numberFormat: Ember.computed('value', {
      get: function get() {
        return this.get('_numberFormat');
      },
      set: function set(key, newValue) {
        if (this.hasUtilsScript() && newValue in intlTelInputUtils.numberFormat) {
          this.set('_numberFormat', newValue);
        }

        return this.get('_numberFormat');
      }
    }),

    /**
     * Get the current number in the format specified by the `numberFormat`
     * property. Note that even if `nationalMode` is enabled, this can still
     * return a full international number. Requires the utilities script.
     *
     * @property number
     * @type String
     * @readonly
     */
    number: Ember.computed('value', 'numberFormat', {
      get: function get() {
        if (this.hasUtilsScript()) {
          var numberFormat = intlTelInputUtils.numberFormat[this.get('numberFormat')];
          return this.$().intlTelInput('getNumber', numberFormat);
        }
      },
      set: function set() {/* no-op */}
    }),

    /**
     * Get the extension part of the current number, so if the number was
     * "+1 (702) 123-1234 ext. 12345" this would return "12345".
     *
     * @property extension
     * @type String
     * @readonly
     */
    extension: Ember.computed('number', {
      get: function get() {
        return this.$().intlTelInput('getExtension');
      },
      set: function set() {/* no-op */}
    }),

    /**
     * Get the country data for the currently selected flag.
     *
     * @property selectedCountryData
     * @type Object
     * @readonly
     */
    selectedCountryData: Ember.computed('value', {
      get: function get() {
        return this.$().intlTelInput('getSelectedCountryData');
      },
      set: function set() {/* no-op */}
    }),

    /**
     * Get the validity of the current `number`.
     *
     * @property isValidNumber
     * @type Boolean
     * @readonly
     */
    isValidNumber: Ember.computed('number', {
      get: function get() {
        return this.$().intlTelInput('isValidNumber');
      },
      set: function set() {/* no-op */}
    }),

    /**
     * Get more information about a validation error. Requires the utilities
     * scripts.
     *
     * @property validationError
     * @type String
     * @readonly
     */
    validationError: Ember.computed('number', {
      get: function get() {
        if (this.hasUtilsScript()) {
          var errorNumber = this.$().intlTelInput('getValidationError');
          for (var key in intlTelInputUtils.validationError) {
            if (intlTelInputUtils.validationError[key] === errorNumber) {
              return key;
            }
          }
        }
      },
      set: function set() {/* no-op */}
    }),

    init: function init() {
      this._super.apply(this, arguments);
      var config = emberIntlTelInputConfig;
      if (config && (config.utilsScript || config.includeUtilsScript)) {
        var utilsScript = config.utilsScript || '/assets/intl-tel-input/js/utils.js';
        if (utilsScript.charAt(0) !== '/') {
          utilsScript = '/' + utilsScript;
        }
        this.set('utilsScript', utilsScript);
      } else {
        this.set('utilsScript', '');
      }
    },


    /**
     * Returns whether the utilities script is present or not.
     *
     * @method hasUtilsScript
     * @return `true` if utilities scripts is present, `false` if not
     */
    hasUtilsScript: function hasUtilsScript() {
      return typeof intlTelInputUtils !== 'undefined';
    },


    /**
     * Initiate the intlTelInput instance.
     *
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      var notifyPropertyChange = this.notifyPropertyChange.bind(this, 'value');

      // let Ember be aware of the changes
      this.$().change(notifyPropertyChange);

      this.$().intlTelInput({
        allowDropdown: this.get('allowDropdown'),
        autoHideDialCode: this.get('autoHideDialCode'),
        autoPlaceholder: this.get('autoPlaceholder'),
        customPlaceholder: this.get('customPlaceholder'),
        dropdownContainer: this.get('dropdownContainer'),
        excludeCountries: this.get('excludeCountries'),
        formatOnDisplay: this.get('formatOnDisplay'),
        geoIpLookup: this.get('geoIpLookup'),
        initialCountry: this.get('initialCountry'),
        nationalMode: this.get('nationalMode'),
        numberType: this.get('numberType'),
        placeholderNumberType: this.get('numberType'),
        onlyCountries: this.get('onlyCountries'),
        preferredCountries: this.get('preferredCountries'),
        separateDialCode: this.get('separateDialCode'),
        utilsScript: this.get('utilsScript')
      });
    },


    /**
     * Destroy the intlTelInput instance.
     *
     * @method willDestroyElement
     */
    willDestroyElement: function willDestroyElement() {
      this.$().intlTelInput('destroy');
    }
  });
});