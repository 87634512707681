define('ember-select/components/x-select', ['exports', 'ember-select/templates/components/x-select'], function (exports, _xSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var isEdgeIe = typeof StyleMedia !== 'undefined';

  exports.default = Ember.Component.extend(Ember.Evented, {
    layout: _xSelect.default,
    classNames: ['ember-select'],
    classNameBindings: ['isOpen:es-open', 'isFocus:es-focus', 'canSearch::es-select', 'multiple:es-multiple'],

    autofocus: false,
    canSearch: true,
    disabled: false,
    dropdown: 'select-dropdown',
    freeText: false,
    isDirty: false,
    isFocus: false,
    isOpen: false,
    openOnFocus: false,
    placeholder: 'Type...',
    required: false,
    token: '',
    value: '',

    labelKey: 'label',
    valueKey: 'value',

    canClear: Ember.computed.and('enabled', 'canSearch', 'hasOptions'),
    canOpen: Ember.computed.or('hasInput', 'openOnFocus'),
    enabled: Ember.computed.not('disabled'),
    hasDropdown: Ember.computed.and('enabled', 'hasModel'),
    hasInput: Ember.computed.notEmpty('token'),
    hasModel: Ember.computed.notEmpty('model'),
    hasOptions: Ember.computed.or('hasInput', 'hasValues'),
    hasValues: Ember.computed.notEmpty('values'),
    multiple: Ember.computed.bool('values'),
    shouldFilter: Ember.computed.or('isDirty', 'multiple', 'hasChanged'),

    input: Ember.computed(function () {
      return document.querySelector('#' + this.elementId + ' input');
    }),

    hasChanged: Ember.computed('token', 'value', function () {
      var token = this.get('token');
      var option = this.get('value');

      if (Ember.isPresent(token) && Ember.isPresent(option)) {
        var _retrieveOption = this.retrieveOption(option),
            label = _retrieveOption.label;

        return token !== label;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.disabled) {
        this.set('canSearch', false);
      }

      if (!this.canSearch) {
        this.set('openOnFocus', true);
      }

      this.set('oldValue', this.get('value'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var value = this.get('value');
      if (Ember.isPresent(value)) {
        Ember.run.next(this, function () {
          return _this.setOption(value);
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      // Need to open on lazy models
      if (this.get('isDirty')) {
        this.open();
      }

      // Update input if value has changed
      var newValue = this.get('value');
      var oldValue = this.get('oldValue');
      if (oldValue !== newValue) {
        var _retrieveOption2 = this.retrieveOption(newValue),
            label = _retrieveOption2.label;

        if (label !== this.get('token')) {
          this.setOption(newValue);
        }
      }

      this.set('oldValue', newValue);
    },


    actions: {
      blur: function blur() {
        // IE bug: prevent closing dropdown on scrollbar click
        if (document.activeElement.classList.contains('es-options')) {
          return;
        }

        if (this.get('isDirty')) {
          // Clear unallowed input in strict single mode
          var option = this.get('freeText') ? this.get('token') : '';
          this.setOption(option, false, !this.get('multiple'));
        }

        this.setProperties({
          isFocus: false,
          isOpen: false
        });
      },
      change: function change(query) {
        /* IE10+ Triggers an input event when focus changes on
         * an input element if the element has a placeholder.
         * https://connect.microsoft.com/IE/feedback/details/810538/
         */
        if (document.documentMode) {
          var isDirty = this.get('isDirty');
          var oldValue = this.get('oldValue') || '';
          if (!isDirty && oldValue === query) {
            return;
          }
        }

        this.set('token', query);
        this.set('isDirty', true);
        this.sendAction('onChange', query);

        if (Ember.isPresent(query)) {
          this.open();
        }
      },
      clear: function clear() {
        this.setOption('', false, !this.get('multiple'));
        this.sendAction('onClear');
        this.send('focus');
      },
      dropdown: function dropdown() {
        var isOpen = this.toggleProperty('isOpen');
        if (isOpen) {
          this.get('input').focus();
        }
      },
      focus: function focus() {
        if (this.get('disabled')) {
          return this.send('blur');
        }

        var input = this.get('input');
        if (input) {
          input.focus();
        }

        if (input && !this.get('isFocus') && this.get('canSearch')) {
          // Select text (similar to TAB)
          input.select();
        }

        if (!this.get('isOpen')) {
          this.open();
        }
      },
      keypress: function keypress(e) {
        var isOpen = this.get('isOpen');

        switch (e.keyCode) {
          case 8:
            {
              // Backspace
              var values = this.get('values');
              if (Ember.isPresent(values) && this.get('token') === '') {
                var last = this.getElement(values, Ember.get(values, 'length') - 1);
                this.attrs.onRemove(last);
                e.preventDefault();
              }

              break;
            }

          case 9: // TAB
          case 13:
            // Enter
            if (isOpen) {
              this.trigger('keyPress', e);
            } else if (this.get('freeText')) {
              this.send('select', this.get('token'), false);
            }

            break;
          case 27:
            // ESC
            if (this.get('canSearch') && this.get('hasInput')) {
              this.send('clear');
            } else {
              this.set('isOpen', false);
            }
            break;
          case 38: // Up Arrow
          case 40:
            // Down Arrow
            if (isOpen) {
              this.trigger('keyPress', e);
            } else {
              this.set('isOpen', true);
            }

            e.preventDefault();
            break;
        }
      },
      remove: function remove(selection) {
        this.attrs.onRemove(selection);
        this.send('focus');
      },
      select: function select(option, selected) {
        var isNew = !selected && this.get('freeText') && this.get('isDirty');
        var allowNew = Ember.isPresent(this.attrs.onCreate);
        var valid = Ember.isPresent(option);

        /* Notify when option is either
         *  - selected
         *  - new, empty and cannot be created */
        var notify = selected || isNew && !allowNew;

        if (allowNew && valid && isNew) {
          this.attrs.onCreate(option);
        }

        this.setOption(option, selected, notify);

        /* Blur on selection when single
         * IE & Edge do not run the events in proper order */
        if (!this.get('multiple') && !isEdgeIe) {
          this.get('input').blur();
        }
      }
    },

    // Handle plain arrays and Ember Data relationships
    getElement: function getElement(model, position) {
      return model[position] || model.objectAt(position);
    },
    open: function open() {
      this.setProperties({
        isOpen: this.get('hasDropdown') && this.get('canOpen'),
        isFocus: true
      });
    },


    /* Retrieve `option`, `value` and `label` given a selection
     * which can be either an option (object) or a value */
    retrieveOption: function retrieveOption(option) {
      var model = this.get('model');
      var label = option;
      var value = option;

      if (Ember.isBlank(option)) {
        label = '';
      } else if ((typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
        label = Ember.get(option, this.get('labelKey'));
        value = Ember.get(option, this.get('valueKey'));
      } else if (Ember.isPresent(model) && _typeof(this.getElement(model, 0)) === 'object') {
        var id = this.get('valueKey');
        option = model.filter(function (x) {
          return Ember.get(x, id) === option;
        }).shift();

        if (option) {
          label = Ember.get(option, this.get('labelKey'));
        }
      }

      return { option: option, value: value, label: label };
    },
    setOption: function setOption(selection, selected, notify) {
      var _retrieveOption3 = this.retrieveOption(selection),
          option = _retrieveOption3.option,
          value = _retrieveOption3.value,
          label = _retrieveOption3.label;

      if (this.get('multiple')) {
        label = '';
      }

      if (!selected && notify && this.get('required')) {
        return this.setOption(this.get('value'));
      }

      if (this.get('canSearch')) {
        this.set('token', label);
      }

      // Ensure the component hasn't been destroyed before updating
      var input = this.get('input');
      if (input) {
        input.value = label;
      }

      this.set('isDirty', false);

      if (notify) {
        this.sendAction('onSelect', value, option, selected);
        this.set('isOpen', false);
      }
    }
  });
});