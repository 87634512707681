define("ember-paper/components/paper-autocomplete", ["exports", "ember-power-select/components/power-select", "ember-paper/templates/components/paper-autocomplete", "ember-paper/mixins/validation-mixin", "ember-paper/mixins/child-mixin", "ember-power-select/utils/group-utils", "ember-paper/utils/calculate-ac-position"], function (_exports, _powerSelect, _paperAutocomplete, _validationMixin, _childMixin, _groupUtils, _calculateAcPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperAutocomplete
   * @extends PowerSelectComponent
   */
  var _default = _powerSelect.default.extend(_validationMixin.default, _childMixin.default, {
    layout: _paperAutocomplete.default,
    calculatePosition: _calculateAcPosition.default,
    triggerComponent: 'paper-autocomplete-trigger',
    contentComponent: 'paper-autocomplete-content',
    optionsComponent: 'paper-autocomplete-options',
    triggerWrapperComponent: 'paper-autocomplete-trigger-container',
    onfocus: Ember.computed.alias('onFocus'),
    onblur: Ember.computed.alias('onBlur'),
    onchange: null,
    oninput: null,
    searchText: '',
    defaultHighlighted: null,
    // Don't automatically highlight any option
    _onChangeNop: function _onChangeNop() {},
    extra: Ember.computed('labelPath', 'label', function () {
      return this.getProperties('label', 'labelPath');
    }),
    validationProperty: Ember.computed('onSearchTextChange', 'onSelectionChange', function () {
      if (this.get('onSearchTextChange')) {
        return 'searchText';
      } else {
        return 'selected';
      }
    }),
    concatenatedDropdownClasses: Ember.computed('dropdownClass', function () {
      var classes = ['md-autocomplete-suggestions-container md-virtual-repeat-container'];

      if (this.get('dropdownClass')) {
        classes.push(this.get('dropdownClass'));
      }

      return classes.join(' ');
    }),
    _onInput: function _onInput(value) {
      var _this = this;

      var handler = this.get('onSearchTextChange') || function (v) {
        return _this.set('searchText', v);
      };

      handler.apply(void 0, arguments);
      return value;
    },
    init: function init() {
      this._initComponent();

      this._super.apply(this, arguments);
    },
    // Init autocomplete component
    _initComponent: function _initComponent() {
      var _this$getProperties = this.getProperties('onSearchTextChange', 'onSelectionChange'),
          onSearchTextChange = _this$getProperties.onSearchTextChange,
          onSelectionChange = _this$getProperties.onSelectionChange;

      var hasTextChange = onSearchTextChange && typeof onSearchTextChange === 'function';
      var hasSelectionChange = onSelectionChange && typeof onSelectionChange === 'function';
      (false && !(hasTextChange || hasSelectionChange) && Ember.assert('{{paper-autocomplete}} requires at least one of the `onSelectionChange` or `onSearchTextChange` functions to be provided.', hasTextChange || hasSelectionChange));
      var aliasOnChangeDepKey = hasSelectionChange ? 'onSelectionChange' : '_onChangeNop';
      Ember.defineProperty(this, 'onchange', Ember.computed.alias(aliasOnChangeDepKey));
      this.oninput = this._onInput.bind(this);
    },
    // Choose highlighted item on key tab
    _handleKeyTab: function _handleKeyTab(e) {
      var publicAPI = this.get('publicAPI');

      if (publicAPI.isOpen && !Ember.isNone(publicAPI.highlighted)) {
        publicAPI.actions.choose(publicAPI.highlighted, e);
      } // e-p-s will close


      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var searchText = this.get('searchText');

      if (searchText !== this.get('publicAPI.searchText')) {
        var publicAPI = this.get('publicAPI');

        if (publicAPI && publicAPI.actions) {
          publicAPI.actions.search(searchText);
        }
      }

      this._super.apply(this, arguments);
    },
    updateSelection: function updateSelection() {
      this._super.apply(this, arguments);

      this.notifyValidityChange();
    },
    actions: {
      onTriggerMouseDown: function onTriggerMouseDown() {
        return false;
      },
      onFocus: function onFocus(event) {
        this.send('activate');
        var publicAPI = this.get('publicAPI');

        if (Ember.isNone(publicAPI.selected)) {
          publicAPI.actions.open(event);
        }

        var action = this.get('onfocus');

        if (action) {
          action(publicAPI, event);
        }
      },
      onBlur: function onBlur(event) {
        this.send('deactivate');
        var action = this.get('onblur');

        if (action) {
          action(this.get('publicAPI'), event);
        }

        this.notifyValidityChange();
      },
      onInput: function onInput(event) {
        var publicAPI = this.get('publicAPI');

        if (!publicAPI.isOpen && event.type !== 'change') {
          publicAPI.actions.open(event);
        }

        this.notifyValidityChange();
        return this._super.apply(this, arguments);
      },
      onCreate: function onCreate(text) {
        if (this.get('onCreate')) {
          this.get('onCreate')(text);
        }

        this.get('publicAPI').actions.close();
      },
      scrollTo: function scrollTo(option) {
        if (!document || !option) {
          return;
        }

        var publicAPI = this.get('publicAPI');
        var optionsList = document.getElementById("ember-power-select-options-".concat(publicAPI.uniqueId));

        if (!optionsList) {
          return;
        }

        var index = (0, _groupUtils.indexOfOption)(publicAPI.results, option);

        if (index === -1) {
          return;
        } // Update the scroll index


        this.updateState({
          scrollIndex: index
        });
      }
    }
  });

  _exports.default = _default;
});