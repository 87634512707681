define("ember-paper/components/paper-autocomplete-highlight", ["exports", "ember-paper/templates/components/paper-autocomplete-highlight"], function (_exports, _paperAutocompleteHighlight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperAutocompleteHighlight
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _paperAutocompleteHighlight.default,
    tagName: 'span',
    flags: '',
    tokens: Ember.computed('regex', 'label', function () {
      var string = "".concat(this.get('label'));
      var regex = this.get('regex');
      var tokens = [];
      var lastIndex = 0; // Use replace here, because it supports global and single regular expressions at same time.

      string.replace(regex, function (match, index) {
        var prev = string.slice(lastIndex, index);

        if (prev) {
          tokens.push({
            text: prev,
            isMatch: false
          });
        }

        tokens.push({
          text: match,
          isMatch: true
        });
        lastIndex = index + match.length;
      }); // Append the missing text as a token.

      var last = string.slice(lastIndex);

      if (last) {
        tokens.push({
          text: last,
          isMatch: false
        });
      }

      return tokens;
    }),
    regex: Ember.computed('searchText', 'flags', function () {
      var flags = this.get('flags');
      var text = this.get('searchText');
      return this.getRegExp(text, flags);
    }),
    getRegExp: function getRegExp(term, flags) {
      var startFlag = '';
      var endFlag = '';
      var regexTerm = this.sanitizeRegex(term);

      if (flags.indexOf('^') >= 0) {
        startFlag = '^';
      }

      if (flags.indexOf('$') >= 0) {
        endFlag = '$';
      }

      return new RegExp(startFlag + regexTerm + endFlag, flags.replace(/[$^]/g, ''));
    },
    sanitizeRegex: function sanitizeRegex(term) {
      return term && term.toString().replace(/[\\^$*+?.()|{}[\]]/g, '\\$&');
    }
  });

  _exports.default = _default;
});