define("ember-paper/components/paper-input", ["exports", "ember-paper/templates/components/paper-input", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin", "ember-invoke-action"], function (_exports, _paperInput, _focusableMixin, _colorMixin, _childMixin, _validationMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperInput
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ChildMixin
   * @uses ColorMixin
   * @uses ValidationMixin
   */
  var _default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    layout: _paperInput.default,
    tagName: 'md-input-container',
    classNames: ['md-default-theme'],
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block', 'placeholder:md-input-has-placeholder'],
    type: 'text',
    autofocus: false,
    tabindex: null,
    hideAllMessages: false,
    isTouched: false,
    iconComponent: 'paper-icon',
    // override validation mixin `isInvalid` to account for the native input validity
    isInvalid: Ember.computed.or('hasErrorMessages', 'isNativeInvalid'),
    hasValue: Ember.computed('value', 'isNativeInvalid', function () {
      var value = this.get('value');
      var isNativeInvalid = this.get('isNativeInvalid');
      return !Ember.isEmpty(value) || isNativeInvalid;
    }),
    shouldAddPlaceholder: Ember.computed('label', 'focused', function () {
      // if has label, only add placeholder when focused
      return Ember.isEmpty(this.get('label')) || this.get('focused');
    }),
    inputElementId: Ember.computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    renderCharCount: Ember.computed('value', function () {
      var currentLength = this.get('value') ? this.get('value').length : 0;
      return "".concat(currentLength, "/").concat(this.get('maxlength'));
    }),
    hasLeftIcon: Ember.computed.bool('icon'),
    hasRightIcon: Ember.computed.bool('iconRight'),
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    validationProperty: 'value',
    // property that validations should be run on
    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-input}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));

      var _this$getProperties = this.getProperties('value', 'errors'),
          value = _this$getProperties.value,
          errors = _this$getProperties.errors;

      var _this$getProperties2 = this.getProperties('_prevValue', '_prevErrors'),
          _prevValue = _this$getProperties2._prevValue,
          _prevErrors = _this$getProperties2._prevErrors;

      if (value !== _prevValue || errors !== _prevErrors) {
        this.notifyValidityChange();
      }

      this._prevValue = value;
      this._prevErrors = errors;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        this._growTextareaOnResize = Ember.run.bind(this, this.growTextarea);
        window.addEventListener('resize', this._growTextareaOnResize);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments); // setValue below ensures that the input value is the same as this.value


      this.setValue(this.get('value'));
      this.growTextarea();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        window.removeEventListener('resize', this._growTextareaOnResize);
        this._growTextareaOnResize = null;
      }
    },
    growTextarea: function growTextarea() {
      if (this.get('textarea')) {
        var inputElement = this.element.querySelector('input, textarea');
        inputElement.classList.add('md-no-flex');
        inputElement.setAttribute('rows', 1);
        var minRows = this.get('passThru.rows');
        var height = this.getHeight(inputElement);

        if (minRows) {
          if (!this.lineHeight) {
            inputElement.style.minHeight = 0;
            this.lineHeight = inputElement.clientHeight;
            inputElement.style.minHeight = null;
          }

          if (this.lineHeight) {
            height = Math.max(height, this.lineHeight * minRows);
          }

          var proposedHeight = Math.round(height / this.lineHeight);
          var maxRows = this.get('passThru.maxRows') || Number.MAX_VALUE;
          var rowsToSet = Math.min(proposedHeight, maxRows);
          inputElement.style.height = "".concat(this.lineHeight * rowsToSet, "px");
          inputElement.setAttribute('rows', rowsToSet);

          if (proposedHeight >= maxRows) {
            inputElement.classList.add('md-textarea-scrollable');
          } else {
            inputElement.classList.remove('md-textarea-scrollable');
          }
        } else {
          inputElement.style.height = 'auto';
          inputElement.scrollTop = 0;

          var _height = this.getHeight(inputElement);

          if (_height) {
            inputElement.style.height = "".concat(_height, "px");
          }
        }

        inputElement.classList.remove('md-no-flex');
      }
    },
    getHeight: function getHeight(inputElement) {
      var offsetHeight = inputElement.offsetHeight;
      var line = inputElement.scrollHeight - offsetHeight;
      return offsetHeight + (line > 0 ? line : 0);
    },
    setValue: function setValue(value) {
      // normalize falsy values to empty string
      value = Ember.isEmpty(value) ? '' : value;

      if (this.element.querySelector('input, textarea').value !== value && value) {
        this.element.querySelector('input, textarea').value = value;
      }
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this = this;

        (0, _emberInvokeAction.invokeAction)(this, 'onChange', e.target.value); // setValue below ensures that the input value is the same as this.value

        Ember.run.next(function () {
          if (_this.isDestroyed) {
            return;
          }

          _this.setValue(_this.get('value'));
        });
        this.growTextarea();
        var inputElement = this.element.querySelector('input');
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      },
      handleBlur: function handleBlur(e) {
        (0, _emberInvokeAction.invokeAction)(this, 'onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });

  _exports.default = _default;
});